<script>
	import '../app.css';

	import Parallax from '$lib/components/Parallax.svelte';
	import Partner from '$lib/components/Partner.svelte';
	import { onMount } from 'svelte';
	import { language } from '$lib/stores/store.js';

	/** @type {import('./$types').PageData} */
	export let data;

	$: fetchedValues = data.fetchedValues;

	let windowX;
	let windowY;
	let y;

	String.prototype.replaceBetween = function (start, end, replace) {
		return this.substring(0, start) + replace + this.substring(end);
	};

	onMount(() => {
		fadeInOnScroll();
		let firstElement = document.querySelector('.firstElement');
		firstElement.classList.add('fadeIn');
		//console.log(fetchedValues.valuesTextData); 
	});

	function fadeInOnScroll() {
		let elements = document.querySelectorAll('.fadeInOnScroll');

		elements.forEach((element) => {
			let elementTop = element.getBoundingClientRect().top;
			let elementBottom = element.getBoundingClientRect().bottom;
			if (elementTop < windowY && elementBottom > 0) {
				element.classList.add('fadeIn');
			} else {
				element.classList.remove('fadeIn');
			}
		});
	}
</script>

<svelte:head>
	<title>Davidoff Immobilien - Ihr Immobilienberater in Dresden</title>
	<meta
		name="description"
		content="Immobilien kaufen und verkaufen in Dresden. Ihr Immobilienmakler in Dresden "
	/>
</svelte:head>

<svelte:window
	on:scroll={fadeInOnScroll}
	bind:scrollY={y}
	bind:innerWidth={windowX}
	bind:innerHeight={windowY}
/>

<section>
	<div class="parallax-image">
		<Parallax image={fetchedValues.parallaxImage} alternativeText={fetchedValues.parallaxAlt} />
	</div>
	<div class="wrapper w-full h-full">
		<div class="triangle" />
		<div class="main">
			<div
				class="{windowX < 800 ? 'containerSmall' : 'container'} firstElement fadeInOnScroll fadeIn"
			>
				<div>
					<h2>
						{#await fetchedValues then aboutMeData}
							{#if $language == 'DE'}
								{fetchedValues.aboutMeData.MyHeader}
							{:else if $language == 'EN'}
								{fetchedValues.aboutMeData.localizations.data[1].attributes.MyHeader}
							{:else if $language == 'RU'}
								{fetchedValues.aboutMeData.localizations.data[0].attributes.MyHeader}
							{/if}
						{/await}
					</h2>
					<p class={windowX < 800 ? 'aboutMeTextSmall' : 'aboutMeText'}>
						{#await fetchedValues then aboutMeData}
							{#if $language == 'DE'}
								{fetchedValues.aboutMeData.MyInformations}
							{:else if $language == 'EN'}
								{fetchedValues.aboutMeData.localizations.data[1].attributes.MyInformations}
							{:else if $language == 'RU'}
								{fetchedValues.aboutMeData.localizations.data[0].attributes.MyInformations}
							{/if}
						{/await}
					</p>
				</div>

				<img
					class={windowX < 800 ? 'pavelImgSmall' : 'pavelImg'}
					src={fetchedValues.aboutMeData.MyPicture.data.attributes.url.replaceBetween(
						50,
						61,
						'q_40'
					)}
					alt={fetchedValues.aboutMeData.MyPicture.data.attributes.alternativeText}
					loading="lazy"
					decoding="async"
					fetchpriority="high"
				/>
			</div>

			<div
				style="flex-direction: row-reverse;"
				class="{windowX < 800 ? 'containerSmall' : 'container'} fadeInOnScroll"
			>
				<div>
					<h2>
						{#await fetchedValues then qualitiesData}
							{#if $language == 'DE'}
								{fetchedValues.qualitiesData.QualitiesHeader}
							{:else if $language == 'EN'}
								{fetchedValues.qualitiesData.localizations.data[1].attributes.QualitiesHeader}
							{:else if $language == 'RU'}
								{fetchedValues.qualitiesData.localizations.data[0].attributes.QualitiesHeader}
							{/if}
						{/await}
					</h2>
					<p class={windowX < 800 ? 'qualitiesTextSmall' : 'qualitiesText'}>
						{#await fetchedValues then qualitiesData}
							{#if $language == 'DE'}
								{fetchedValues.qualitiesData.QualitiesText}
							{:else if $language == 'EN'}
								{fetchedValues.qualitiesData.localizations.data[1].attributes.QualitiesText}
							{:else if $language == 'RU'}
								{fetchedValues.qualitiesData.localizations.data[0].attributes.QualitiesText}
							{/if}
						{/await}
					</p>
					<div class={windowX < 800 ? 'strategyTextSmall' : 'strategyText'}>
						{#await fetchedValues then meineStrategieData}
							{#each fetchedValues.meineStrategieData.sort((a, b) => a.id - b.id) as msd}
								<div
									style="width:100%; display:flex; gap:2rem; flex-direction:row; padding: 1rem 0; justify-content: space-between; align-items:center;"
								>
									<img
										class="strategy-icon"
										src={msd.attributes.icon.data.attributes.url.replaceBetween(50, 61, 'q_10')}
										alt={msd.attributes.icon.data.attributes.alternativeText}
										loading="lazy"
										decoding="async"
										fetchpriority="low"
									/>

									{#if $language == 'DE'}
										<p>{msd.attributes.StrategieText}</p>
									{:else if $language == 'EN'}
										<p>{msd.localizations.data[1].attributes.StrategieText}</p>
									{:else if $language == 'RU'}
										<p>{msd.localizations.data[0].attributes.StrategieText}</p>
									{/if}
								</div>
							{/each}
						{/await}
					</div>
				</div>

				<img
					class={windowX < 800 ? 'containerImgSmall' : 'containerImg'}
					src={fetchedValues.qualitiesData.QualitiesPicture.data.attributes.url.replaceBetween(
						50,
						61,
						'q_10'
					)}
					alt={fetchedValues.qualitiesData.QualitiesPicture.data.attributes.alternativeText}
					loading="lazy"
					decoding="async"
					fetchpriority="low"
				/>
			</div>

			<div class="service-container fadeInOnScroll">
				<h1>Leistungen</h1>
				<div class="services">
					{#await fetchedValues then serviceData}
						{#each fetchedValues.serviceData.sort((a, b) => a.id - b.id) as sd}
							<div class={windowX < 800 ? 'service-item-small' : 'service-item'}>
								<h3 class={windowX < 800 ? 'service-item-header-small' : 'service-item-header'}>
									{#if $language == 'DE'}
										{sd.attributes.ServiceHeader}
									{:else if $language == 'EN'}
										{sd.attributes.localizations.data[1].attributes.ServiceHeader}
									{:else if $language == 'RU'}
										{sd.attributes.localizations.data[0].attributes.ServiceHeader}
									{/if}
								</h3>
								<p class={windowX < 800 ? 'service-item-text-small' : 'service-item-text'}>
									{#if $language == 'DE'}
										{sd.attributes.ServiceDescription}
									{:else if $language == 'EN'}
										{sd.attributes.localizations.data[1].attributes.ServiceDescription}
									{:else if $language == 'RU'}
										{sd.attributes.localizations.data[0].attributes.ServiceDescription}
									{/if}
								</p>
							</div>
						{/each}
					{/await}
				</div>
				<div class="services-href">
					<a href="/leistungen" class="services-href">mehr erfahren</a>
				</div>
			</div>

			<Partner fadeClass="fadeInOnScroll" />

			<div class="values-container fadeInOnScroll">
				<h2>Meine Werte</h2>
				<div class="values-row">
					{#await fetchedValues then valuesTextData}
						{#each fetchedValues.valuesTextData.sort((a, b) => a.id - b.id) as vd}
							<div class={windowX < 600 ? 'values-item-small' : 'values-item'}>
								<img
									class="values-image"
									src={vd.attributes.WerteImage.data.attributes.url.replaceBetween(
										50,
										61,
										'q_auto:eco'
									)}
									alt={vd.attributes.WerteImage.data.attributes.alternativeText}
									loading="lazy"
									decoding="async"
									fetchpriority="low"
								/>

								<p class={windowX < 600 ? 'values-text-small' : 'values-text'}>
									{#if $language == 'DE'}
										{vd.attributes.WerteText}
									{:else if $language == 'EN'}
										{vd.attributes.localizations.data[1].attributes.WerteText}
									{:else if $language == 'RU'}
										{vd.attributes.localizations.data[0].attributes.WerteText}
									{/if}
								</p>
							</div>
						{/each}
					{/await}
				</div>
			</div>
		</div>
	</div>
</section>

<style>
	/* Animationen */
	@keyframes -global-from-left {
		0% {
			transform: rotateX(50deg) translateX(-200vw) skewX(-50deg);
			opacity: 1;
		}
		100% {
			transform: rotateX(0deg) translateX(0) skewX(0deg);
			opacity: 1;
		}
	}

	@-webkit-keyframes -global-scale-up-center {
		0% {
			-webkit-transform: scale(0.5);
			transform: scale(0.5);
			opacity: 0;
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opactiy: 1;
		}
	}
	@keyframes -global-scale-up-center {
		0% {
			-webkit-transform: scale(0.5);
			transform: scale(0.5);
			opacity: 0;
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	:global(.fadeIn) {
		animation: fadeIn 0.8s ease-in-out;
	}
	.fadeInOnScroll {
		opacity: 0;
		animation-fill-mode: forwards;
	}

	/* Begin Custom Styling */

	:root {
		--primary-color: #aa9173;
	}

	/* Media Queries */

	@media (min-width: 1536px) {
		.service-container,
		.values-container {
			max-width: 1536px;
		}
	}

	@media only screen and (min-width: 900px) {
		.values-item {
			width: 200px;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}

	/* Generell Custom Styling */

	.parallax-image {
		background-color: white;
	}

	.triangle {
		width: 100%;
		height: 5vh;
		background-color: white;
		clip-path: polygon(50% 0%, 0 100%, 100% 100%);
	}
	p {
		line-height: 1.2rem;
		hyphens: auto;
		text-align: justify;
	}

	h2 {
		font-size: 2rem !important;
		padding: 0 0 1rem 0;
		color: var(--primary-color);
		font-weight: 200;
	}
	/* Desktop Custom Styling */

	.wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		margin: -5rem 0 0 0;
	}
	picture {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}

	.main {
		width: 100%;
		margin-top: -1px;
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.main > div {
		width: 90%;
	}

	.container {
		margin: 5rem 0;
		width: 100%;
		display: flex;
		flex-direction: row;
		position: relative;
		align-items: center;
		justify-content: space-between;
	}
	.container div {
		position: relative;
		width: 50%;
	}
	.aboutMeText,
	.qualitiesText,
	.strategyText {
		width: 100% !important;
		text-align: justify;
		padding: 1rem 0;
		display: flex;
		font-size: min(2vw, 25px);
		line-height: min(2.2vw, 2rem);
		flex-direction: column;
	}
	.strategyText > * > p {
		font-size: min(2vw, 25px);
		line-height: min(2.2vw, 2rem);
	}
	.strategyText > * > p,
	.strategyTextSmall > * > p {
		text-align: left !important;
		width: 100%;
	}
	.pavelImg {
		max-width: 40%;
		min-width: 300px;
		min-height: 400px;
		position: absolute;
		right: 0;
		top: 0;
		object-fit: cover;
		height: 30vh;
		overflow: hidden;
		box-shadow: 3px 3px 10px var(--primary-color);
	}
	.pavelImgSmall {
		width: 80%;
		max-height: 300px;
		object-fit: cover;
		margin: 1rem auto;
	}
	.containerImg {
		max-width: 40%;
		min-width: 300px;
		min-height: 400px;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		height: 50vh;
		overflow: hidden;
		box-shadow: 3px 3px 10px var(--primary-color);
	}
	.containerImgSmall {
		width: 80%;
		max-height: 250px;
		object-fit: cover;
		margin: 1rem auto;
	}
	.service-container {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.service-container h1 {
		font-size: 3rem;
		font-weight: 200;
		color: var(--primary-color);
		text-align: center;
		padding: 5rem 0;
	}
	.services {
		width: 100%;
		position: relative;
		background-color: rgb(255, 255, 255);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: 0 auto 0 auto;
	}

	.service-item {
		width: 30%;
		min-width: 180px;
		height: 250px;
		position: relative;
		align-items: center;
		display: flex row;
		border: 1px solid var(--primary-color);
		margin: 0.5rem;
		overflow: scroll;
		box-shadow: 1px 1px 10px var(--primary-color);
	}

	.service-item-header {
		margin: 1rem 0 0rem 0;
		font-size: min(2.5vh, 2rem);
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;
	}
	.service-item-text {
		font-size: min(1.2vw, 1.2rem);
		font-weight: 200;
		text-align: center;
		margin: 1rem;
		hyphens: auto;
		-webkit-hyphens: auto;
		text-align: justify;
		line-height: 1.7rem !important;
	}
	.services-href {
		z-index: 10;
		font-size: 1rem;
		margin: 1rem 0 0 0;
		color: var(--primary-color);
		white-space: nowrap;
		text-decoration: underline;
		text-underline-offset: 7px;
		cursor: pointer;
		text-transform: uppercase;
	}
	.services-href:hover {
		cursor: pointer;
	}
	.values-container {
		display: flex;
		width: 100%;
		margin: 5rem auto;
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}
	.values-container h1 {
		font-size: 3rem;
		font-weight: 200;
		color: var(--primary-color);
		text-align: center;
		padding: 4rem 0;
	}

	.values-row {
		width: 100%;
		position: relative;
		min-height: 15vh;
		background-color: rgb(255, 255, 255);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	.values-item {
		width: auto;
		max-width: 400px;
		height: auto;
		border-radius: 10px;
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.values-image {
		transform: scale(0.5);
	}

	.values-text {
		font-size: min(2vw, 1.5rem);
		margin: -4rem 0 0 0;
		text-align: center;
		font-weight: 400;
		width: auto;
		white-space: nowrap;
		z-index: 10;
	}

	/* Mobile Custom Styling */

	.containerSmall {
		margin: 1rem 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.containerSmall div {
		width: 80%;
		margin: 0 auto;
	}

	.aboutMeTextSmall,
	.qualitiesTextSmall,
	.strategyTextSmall {
		font-size: 2vh !important;
		width: 100% !important;
		line-height: min(2.2rem, 4.5vw);
		text-align: justify;
		padding: 1rem 0;
		hyphens: auto;
		-webkit-hyphens: auto;
		word-spacing: -0.02em;
	}
	.service-item-small {
		width: 80%;
		min-width: 180px;
		height: 250px;
		position: relative;
		align-items: center;
		display: flex row;
		border: 1px solid var(--primary-color);
		margin: 0.5rem;
		overflow: scroll;
		box-shadow: 1px 1px 10px var(--primary-color);
	}

	.service-item-header-small {
		margin: 1rem 0 2rem 0;
		font-size: 2.2vh;
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;
	}
	.values-row-small {
		width: 100%;
		min-height: 30vh;
		background-color: rgb(255, 255, 255);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}
	.values-item-small {
		width: auto;
		max-width: 300px;
		height: auto;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.service-item-text-small {
		font-size: 1.8vh;
		font-weight: 200;
		text-align: center;
		margin: 1rem;
		hyphens: auto;
		text-align: justify;
		line-height: 1.2rem !important;
	}
	.values-text-small {
		font-size: 2.2vh;
		margin-top: -4rem;
		text-align: center;
		font-weight: bold;
		width: auto;
		z-index: 10;
	}
</style>

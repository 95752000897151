<script>
	import { Motion } from 'svelte-motion';
	import { onMount } from 'svelte';
	import { language } from '$lib/stores/store.js';

	let y;
	let imageUrl;
	let ScreenY;
	let ScreenX;
	let innerWidth;
	let innerHeight;

	export let image;
	export let alternativeText;
	let img = image;

	const PROD_URL = 'https://davidoff-immo.up.railway.app';

	let parallaxData = [];
	let promise = getParallaxData();

	async function getParallaxData() {
		const parallax = await fetch(
			PROD_URL + '/api/parallaxes?fields[0]=Ueberschrift&fields[1]=SubTitle&fields[2]=localizations'
		);
		parallaxData = await parallax.json();
		return parallaxData;
	}

	String.prototype.replaceBetween = function (start, end, replace) {
		return this.substring(0, start) + replace + this.substring(end);
	};

	onMount(async () => {
		updateMotion();
		window.addEventListener('resize', updateMotion);
		//console.log(img.replaceBetween(50, 61, 'q_80'));
	});

	function updateMotion() {
		innerWidth = window.innerWidth;
		innerHeight = window.innerHeight;
		ScreenY = innerHeight / 3500;
		ScreenX = innerWidth / 2000;
	}
	let width;
</script>

<svelte:window bind:scrollY={y} bind:innerWidth={width} />

<section>
	<div class="parallax-container">
		{#await image then img}
			<picture>
				<img
					class=""
					style="transform: translate(0,{(-y * 0.4) / 1}px)"
					src={img.replaceBetween(50, 61, 'q_40')}
					alt={alternativeText}
					decoding="async"
					fetchpriority="high"
				/>
			</picture>
		{/await}
	</div>
	<div class="text">
		<div class="flex flex-col justify-center items-center">
			<Motion
				animate={{
					translateX: 1,
					translateY: y * 2,
					scale: 1,
					opacity: 1 / (y * 0.01 + 1)
				}}
				transition={{ duration: 0 }}
				let:motion
			>
				<div
					style="height:{width / 4}px"
					use:motion
					class="flex flex-col justify-center items-center align-center"
				>
					{#await promise}
						<!--<p>Loading...</p>-->
					{:then value}
						{#if $language == 'DE'}
							<h1 class="text-white">{value.data[0].attributes.Ueberschrift}</h1>
							<h2 class="text-white text-center">{value.data[0].attributes.SubTitle}</h2>
						{:else if $language == 'EN'}
							<h1 class="text-white">
								{value.data[0].attributes.localizations.data[1].attributes.Ueberschrift}
							</h1>
							<h2 class="text-white text-center">
								{value.data[0].attributes.localizations.data[1].attributes.SubTitle}
							</h2>
						{:else if $language == 'RU'}
							<h1 class="text-white">
								{value.data[0].attributes.localizations.data[0].attributes.Ueberschrift}
							</h1>
							<h2 class="text-white text-center">
								{value.data[0].attributes.localizations.data[0].attributes.SubTitle}
							</h2>
						{/if}
					{:catch error}
						<p>{error.message}</p>
					{/await}
				</div>
			</Motion>
		</div>
	</div>
</section>

<style>
	:root {
		--primary-color: #aa9173;
	}
	section {
		z-index: -1;
		height: 60vh;
		overflow: hidden;
	}
	h1 {
		opacity: calc(1 - y / 1000);
		margin: 0 auto;
		text-align: center;
		font-size: min(5vw, 5rem);
	}
	h2 {
		margin: 0 auto;
		white-space: nowrap;
		text-align: center;
		font-size: min(3vw, 3rem);
	}
	img {
		will-change: transform;
		position: absolute;
		height: 60vh;
		width: 100vw;
		object-fit: cover;
	}
	.text {
		position: relative;
		height: 100%;
		color: var(--primary-color);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}

	.text div div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

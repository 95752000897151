/** @type {import('./$types').PageLoad} */
const PROD_URL = 'https://davidoff-immo.up.railway.app';

export async function load({ fetch }) {
	try {
		let aboutMe = await fetch(
			PROD_URL +
				'/api/about-mes?fields[1]=MyHeader&fields[2]=MyInformations&populate[MyPicture][fields][0]=url&populate[MyPicture][fields][1]=alternativeText&populate=localizations'
		);
		let aboutMeData = await aboutMe.json();

		let qualities = await fetch(PROD_URL + '/api/personal-qualities?populate=*');
		let qualitiesData = await qualities.json();

		let values = await fetch(PROD_URL + '/api/meine-wertes?populate=*');
		let valuesData = await values.json();

		let services = await fetch(PROD_URL + '/api/services?populate=*');
		let servicesData = await services.json();

		let parallax = await fetch(
			PROD_URL +
				'/api/parallaxes?fields[1]=Ueberschrift&fields[2]=SubTitle&populate[FullScreenPicture][fields][0]=url&populate[FullScreenPicture][fields][1]=alternativeText&populate=localizations'
		);
		let parallaxData = await parallax.json();

		let meineStrategie = await fetch(PROD_URL + '/api/meine-strategies?populate=*');
		let meineStrategieData = await meineStrategie.json();

		return {
			fetchedValues: {
				aboutMeData: aboutMeData.data[0].attributes,
				qualitiesData: qualitiesData.data[0].attributes,
				strategyData: qualitiesData.data[0].attributes,
				serviceData: servicesData.data,
				valuesTextData: valuesData.data,
				parallaxImage: parallaxData.data[0].attributes.FullScreenPicture.data.attributes.url,
				parallaxAlt:
					parallaxData.data[0].attributes.FullScreenPicture.data.attributes.alternativeText,
				parallax: parallaxData.data[0].attributes.FullScreenPicture.data.attributes,
				meineStrategieData: meineStrategieData.data
			}
		};
	} catch (err) {
		console.log(err);
	}
}

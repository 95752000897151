<script>
	import { onMount } from 'svelte';
	import { language } from '$lib/stores/store.js';

	export let fadeClass;

	const PROD_URL = 'https://davidoff-immo.up.railway.app';
	let partnerData = [];

	String.prototype.replaceBetween = function (start, end, replace) {
		return this.substring(0, start) + replace + this.substring(end);
	};

	onMount(async () => {
		const partner = await fetch(PROD_URL + '/api/partners?populate=*');
		partnerData = await partner.json();
		partnerData = partnerData.data;
	});
</script>

<section class={fadeClass}>
	<h2>Meine Partner</h2>
	<div>
		{#each partnerData as partner}
			<div class="partnerBox">
				<h3>{partner.attributes.name}</h3>
				<a href={partner.attributes.url}
					><img
						src={partner.attributes.logo.data.attributes.url.replaceBetween(50, 61, 'q_10')}
						alt={partner.attributes.logo.data.attributes.alternativeText}
					/>
				</a>
			</div>
		{/each}
	</div>
</section>

<style>
	section {
		margin: 2rem 0;
		position: relative;
		width: 90%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	section > div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 2rem;
		align-items: center;
	}
	h2 {
		color: #aa9173;
		font-size: 3rem;
		margin: 2rem 0;
	}
	h3 {
		color: #aa9173;
		font-size: 1.5rem;
	}
	.partnerBox {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		gap: 1rem;
		align-items: center;
	}
	a {
		position: relative;
		transition: all 0.2s ease-in-out;
	}
	img {
		margin: 0 auto;
		position: relative;
		transition: all 0.2s ease-in-out;
		width: 35%;
		height: 300px;
		object-fit: contain;
		min-width: 150px;
		max-width: 250px;
	}
	a::after {
		content: '+';
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		font-size: 6rem;
		transform: translate(-50%, -50%);
		text-transform: uppercase;
		font-weight: 200;
		color: #aa9173;
		display: none;
		transition: all 0.2s ease-in-out;
		filter: drop-shadow(0 0 5px white);
	}

	a:hover::after {
		display: block;
	}
	a:hover img {
		filter: sepia(80%);
		opacity: 0.5;
	}

	@media screen and (max-width: 768px) {
		section > div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 1rem;
			align-items: center;
		}
	}
</style>
